.tiles1 {
  margin-left: 0;
  margin-right: 0;
}
.cms_item.tiles1 .tile1 {
  @media screen and (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tile1 .pageTile__content.tile__content {
  position: relative;
}
.tile1 .pageTile_details {
  display: none;
}
.tile1 .pageTile__content.tile__content:hover .pageTile_details,
.tile1 .pageTile__content.tile__content:active .pageTile_details {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.tiles1 .tile1 .tile__bg,
.tile1 .pageTile_details {
  width: 350px;
  height: 350px;
  padding: 30px;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 340px;
    padding: 16px;
  }
}
.tile1 .pageTile__content:hover .pageTile_details,
.tile1 .pageTile__content:focus .pageTile_details {
  background-color: white;
}
.tile1 .pageTile__content:hover .pageTile__title,
.tile1 .pageTile__content:hover .pageTile_desc,
.tile1 .pageTile__content:active .pageTile__title,
.tile1 .pageTile__content:active .pageTile_desc,
.tile1 .pageTile__content:focus .pageTile__title,
.tile1 .pageTile__content:focus .pageTile_desc {
  color: #fff;
}
.tile1 .tile_button {
  color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover,
  &:active {
    background: #fff;
    color: #000;
    text-decoration: none;
  }
}
.tile1 .pageTile__title {
  font-size: 1rem;
  margin: 0.7rem 0;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    margin: 1.3rem 0;
  }
}
.tile1 .pageTile_desc {
  line-height: 1.2;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    line-height: 1.4;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}
.tile1 .pageTile__title_bg {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  padding: 1.5rem 1.4rem 0.4rem;
  font-size: 1.3rem;
  line-height: 1.2;
  color: white;
  height: 30%;
  font-family: $family-base;
  background: linear-gradient(180deg, transparent 0, #000);
  display: flex;
  align-items: center;
}
