.container {
  max-width: 1400px;
}

.tiles2 {
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
}
.cms_item.tiles2 .tile2 {
  flex: 0 0 24%;
  // max-width: 25%;
  
  @media screen and (max-width: 1110px) {
    flex: 0 0 48%;
    // max-width: 100%;
  }

  @media screen and (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tile2 .pageTile__content.tile__content {
  position: relative;
}
.tile2 .pageTile_details {
  padding: 1rem 1.5rem;
  position:absolute;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
}

.tiles .tile2 .tile__bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tile2 .pageTile__content.tile__content:hover .pageTile__title_bg,
.tile2 .pageTile__content.tile__content:active .pageTile__title_bg {
  display: none;
}

.tile2 .pageTile__content.tile__content:hover .pageTile_details,
.tile2 .pageTile__content.tile__content:active .pageTile_details {
  opacity: 1;
  transform: translateY(0);
  // position: absolute;
  // top: 0;
  // left: 0;
}
.tiles2 .tile2 .tile__bg,
.tile2 .pageTile_details {
  width: 100%;
  height: 300px;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 340px;
    padding: 16px;
  }
}
.tile2 .pageTile__content:hover .pageTile_details,
.tile2 .pageTile__content:focus .pageTile_details {
  background-color: rgba(black, 0.7);
}
.tile2 .pageTile__content:hover .pageTile__title,
.tile2 .pageTile__content:hover .pageTile_desc,
.tile2 .pageTile__content:active .pageTile__title,
.tile2 .pageTile__content:active .pageTile_desc,
.tile2 .pageTile__content:focus .pageTile__title,
.tile2 .pageTile__content:focus .pageTile_desc {
  color: #FFF;
}
.tile2 .tile_button {
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #FFF;
  background: transparent;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover,
  &:active {
    background: #FFF;
    color: #000;
    text-decoration: none;
  }
}
.tile2 .pageTile__title {
  font-size: 1.2rem;
  margin: 1rem 0;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    margin: 1.3rem 0;
  }
}
.tile2 .pageTile_desc {
  line-height: 1.2;
  font-size: 16px;
  margin-bottom: 1rem;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    line-height: 1.4;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}
.tile2 .pageTile__title_bg {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  padding: 1.5rem 1.4rem 0.4rem;
  font-size: 1.3rem;
  line-height: 1.2;
  color: white;
  height: 30%;
  font-family: $family-base;
  font-weight: $weight-bold;
  background: linear-gradient(180deg, transparent 0, #000);
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
