.pageTile {
    position: relative;
    flex: 0 0 24%;
}

.pageTile__bg {
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.pageTile__title--bg {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
    padding: 1.5rem 1.4rem 0.4rem;
    font-size: 1.3rem;
    line-height: 1.2;
    color: white;
    height: 30%;
    font-family: $family-base;
    background: linear-gradient(180deg, transparent 0, #000);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.pageTile__details {
    font-family: $family-base;
    background-color: rgba(black, 0.7);
    
    padding: .5rem 1rem;
    width: 100%;
    position: absolute;
    top: 0;
    display: none;
    // transform: translateY(100%);
    // opacity: 0;
    // transition: 0.3s ease-in-out;
}

.pageTile__details--title {
    text-transform: uppercase;
}

.pageTile__details--desc {
    margin-block: 1rem;
}
  
.pageTile__button {
    color: white;
    border-radius: 4px;
    border: 1px solid #FFF;
    background: transparent;
    padding: 0.2rem 1rem;
    font-weight: $weight-bold;
    font-size: 1rem;
    &:focus,
    &:hover,
    &:active {
      background: #FFF;
      color: #000;
      border: 0;
      text-decoration: none;
    }
  }

  .pageTile2 .pageTile__content.pageTile__content:hover .pageTile__details,
  .pageTile2 .pageTile__content.pageTile__content:active .pageTile__details {
    display: block;
  }

  .pageTile.pageTile__bg:hover {
    background-color: red;
  }
