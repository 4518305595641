.bottomMenu {
  background-color: $primary-color;
  color: white;
  position: fixed;
  height: 94px;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;

  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    // max-width: 400px;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      width: 660px;
      max-width: 660px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
    padding: 0.2rem 0;
  }

  @media screen and (max-width: 576px) {
    height: 77px;
  }
}


.bottomMenu__li {
  transition: all 0.5s ease-out;
  // &:hover, &:focus, &.selected {
  //   background-color: rgba(#000, 0.1);
  // }
}

.bottomMenu__link {

  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  
  //padding-bottom: 10px;
  font-family: $family-header;

  * {
    position: relative;
    z-index: 20000;
  }

  .icon {
    width: 32px;
    height: 32px;
    //@include bg-img();
    margin: auto;
    //transition: all 0.3s ease-out;
  }

  div {
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.4rem;
  }

  &:hover,
  &:focus,
  &.selected {
    transform: translate(0, -4px);
    color: white;
    text-decoration: none;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 5px;

    .icon {
      width: 40px;
      height: 40px;
    }

    div {
      font-size: 13px;
      letter-spacing: 0.5px;
    }
  }
}

.icon {
  img { width: 100%; height: 100%; }
  .normal { display: block; }
  .selected { display: none; }
}

.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
  .normal { display: none; }
  .selected { display: block; }
}


//https://tympanus.net/Development/IconHoverEffects/#set-8